import { AppBar } from '@material-ui/core';
import React from 'react';
import './App.css';
import logo from './assets/Stad-antwerpen-logo.jpg';
import { Dashboard } from './components/dashboard.component';

function App() {
    return (
        <div className="App">
            <AppBar position="sticky">
                <img src={logo} alt="logo" width={75} />
            </AppBar>
            <Dashboard />
        </div>
    );
}

export default App;
