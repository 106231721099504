import { List } from '@material-ui/core';
import { FC } from 'react';
import { HistoryDto } from '../dto';
import { HistoryListItem } from './HistoryListItem.component';

interface Props {
    history: HistoryDto[],
}

export const ScanHistory: FC<Props> = ({ history }) => {
    if (!history.length) return (<></>);
    return (
        <List disablePadding={true} dense={true}>
            {history.map(history => {
                return <HistoryListItem key={history.id} historyItem={history} />
            })}
        </List>
    )
}
