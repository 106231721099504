import { Box, createStyles, LinearProgress, makeStyles, Theme, Typography, withStyles } from '@material-ui/core';
import { FC } from 'react';
import { SatisfactionDto } from '../dto';

interface Props {
    satisfaction: SatisfactionDto;
}

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: 'green',
        },
    }))(LinearProgress)

const useStyles = makeStyles(() => ({
    bar: {
        backgroundColor: (props: {progress: number}) => {
            if (props.progress > 100) {
                return 'red';
            } else if (props.progress === 100) {
                return 'green';
            } else {
                return 'lightblue';
            }
        },
    },
}))

export const SatisfactionBar: FC<Props> = ({ satisfaction }) => {
    const progress = Math.round(satisfaction.satisfaction / satisfaction.need * 100);
    const showProgress = progress > 100 ? 100 : progress;
    const classes = useStyles({ progress });
    return (
        <div>
            <p>{satisfaction.productType}</p>
            <div>
                <Box display="flex" flexDirection="column" alignItems="center" alignContent="center">
                    <Box width="100%" mr={1}>
                        <BorderLinearProgress classes={{
                            bar: classes.bar,
                        }} variant="determinate" value={showProgress} />
                    </Box>
                    <Box paddingTop={1} minWidth={35}>
                        <Typography variant="body2" color="textPrimary">{`${satisfaction.satisfaction} / ${satisfaction.need} gr`}</Typography>
                    </Box>
                </Box>
            </div>
        </div>
    )
}
