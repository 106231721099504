import { Box } from '@material-ui/core';
import { FC } from 'react';
import { SatisfactionDto } from '../dto';
import { SatisfactionBar } from './satisfactionBar.component';

interface Props {
    satisfactions: SatisfactionDto[];
}

export const Satisfaction: FC<Props> = ({ satisfactions }) => {
    return (
        <Box display={'flex'} flexDirection="column" paddingLeft={5} paddingRight={5}>
            {satisfactions.map((satisfaction) => {
                return (
                    <SatisfactionBar key={satisfaction.productType} satisfaction={satisfaction} />
                )
            })}
        </Box>
    )
}
