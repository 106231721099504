import { Box, ListItem, ListItemText, Typography } from '@material-ui/core';
import { FC } from 'react';
import { HistoryDto } from '../dto';

interface Props {
    historyItem: HistoryDto;
}

export const HistoryListItem: FC<Props> = ({ historyItem }) => {
    const text = `${historyItem.amount === 1 ? `${historyItem.weight} gr` : `${historyItem.amount} * ${historyItem.weight} gr`} ${historyItem.product}`;
    const primary = <Typography
        variant="body1"
        component="span"
        color="textPrimary"
    >
        <Box fontWeight="bold">
            {text}
        </Box>
        <Box>- {historyItem.operator}</Box>
    </Typography>
    return (
        <ListItem style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} alignItems="center">
            <ListItemText primary={primary} />
        </ListItem>
    )
}
