import { Box } from '@material-ui/core';
import { FC, useCallback, useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';
import icon from '../assets/Icon.jpg';
import { config } from '../config';
import { WebsocketDto } from '../dto';
import { ScanHistory } from './history.component';
import { Satisfaction } from './satisfaction.component';

export const Dashboard: FC = () => {
    const [websocketData, setWebsocketData] = useState<WebsocketDto>();
    const [isLinked, setIsLinked] = useState<boolean>(false);
    const dashboardId = config.dashboardId;

    const handleWSMessage = useCallback((data) => {
        console.log('dashboard.component - websocket received: ', data);
        if (data.dashboardId === dashboardId) {
            setIsLinked(true);
            setWebsocketData(data);
        } else {
            setIsLinked(false);
        }
    }, [setIsLinked, dashboardId])


    useEffect(() => {
        const socket = socketIOClient('https://noodhulp-scanner-api-staging.codeer.cloud',{ transports: ['websocket'] })
        socket.on('msgToClient', handleWSMessage);

        return () => {
            socket.close();
        }
    }, [handleWSMessage])

    return (
        <div>
            {!isLinked ?
                (
                    <>
                        <h1>Dashboard - {dashboardId}</h1>
                        <img src={icon} alt="icon" />
                    </>
                ) :
                (
                    <>
                        <Box borderBottom={'1px solid black'}>
                            <h1>Operator: {websocketData?.operator}</h1>
                        </Box>

                        <Box display="flex" minHeight={600}>
                            <Box borderRight={'1px solid black'} display="flex" flexDirection="column" width={'33%'} height={'100%'}>
                                <Box borderBottom={'1px solid black'} height={100}>
                                    <h2>
                                        Van: {websocketData?.origin}
                                    </h2>
                                </Box>
                                <Box borderBottom={'1px solid black'} minHeight={100}>
                                    <p>Huidig product:</p>
                                    <h2>{websocketData?.product}</h2>
                                    {websocketData?.amount && websocketData?.weight && (
                                        <p>{`${websocketData?.amount * websocketData?.weight} gr`}</p>
                                    )}
                                </Box>
                                <Box borderBottom={'1px solid black'} alignItems={'center'} minHeight={400}>
                                    <p>Geschiedenis:</p>
                                    <ScanHistory history={websocketData?.history || []} />
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" width={'100%'} borderBottom={'1px solid black'}>
                                <h2>Naar : {websocketData?.target}</h2>
                                <Satisfaction satisfactions={websocketData?.satisfaction || []} />
                            </Box>
                        </Box>
                    </>

                )
            }
        </div>
    )
}
